import { useMemo } from 'react';

import { useGetAccessibilityContent } from './contentstack';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export type AccessibilityContent = {
  aria: { [key: string]: string };
};

export const useAccessibilityContent = (contentOptions?: ContentOptions): AsyncResult<AccessibilityContent> => {
  const {
    data: contentData,
    error,
    loading,
  } = useGetAccessibilityContent({
    variables: contentOptions,
    skip: !contentOptions,
  });

  const data = useMemo(() => {
    if (!contentData) {
      return;
    }
    const content = contentData.all_accessibility?.items?.[0];
    return {
      aria: (content?.aria ?? []).reduce<AccessibilityContent['aria']>((acc, a) => {
        if (a?.generic?.key && a.generic.value && !acc[a.generic.key]) {
          acc[a.generic.key] = a.generic.value;
        }
        return acc;
      }, {}),
    };
  }, [contentData]);

  return { data, error, loading };
};
