import { CompactVariant, Indicator, IndicatorLabels, PortfolioBand } from '.';

interface IndicatorOptions {
  bandIndex: number;
  compact?: CompactVariant;
  editMode?: boolean;
  fromEditAccountModal?: boolean;
  indicatorLabels?: IndicatorLabels;
  portfolioBands?: PortfolioBand[];
  riskBandCurrent?: number;
  riskBandNew: number;
  riskBandUser?: number;
  showPortfolioBands?: boolean;
  sliderBand?: number;
}

export const getIndicator = (indicatorOptions: IndicatorOptions): Indicator => {
  const {
    bandIndex,
    riskBandNew,
    compact,
    editMode,
    indicatorLabels,
    portfolioBands,
    riskBandCurrent,
    riskBandUser,
    showPortfolioBands,
    sliderBand,
    fromEditAccountModal,
  } = indicatorOptions;

  const isPrimary: boolean = false;
  if (fromEditAccountModal && !editMode) {
    if (bandIndex === sliderBand) {
      return {
        isPrimary: true,
        label: indicatorLabels?.current,
      };
    } else {
      return {};
    }
  }

  const riskBandNewLabel = fromEditAccountModal // This comes from Account Actions, only current value is valid in this scenario.
    ? indicatorLabels?.current
    : riskBandCurrent === riskBandNew
      ? indicatorLabels?.noChange
      : indicatorLabels?.new;

  if (compact === CompactVariant.Arrow) {
    return {
      isPrimary,
      label: bandIndex === sliderBand ? indicatorLabels?.current : undefined,
    };
  }

  // If showPortfolioBands is true label and isPrimary is calculated based on riskBandIndex of the
  // portfolioBand.
  if (showPortfolioBands && portfolioBands) {
    if (bandIndex === portfolioBands[riskBandNew].riskBandIndex) {
      return {
        label: riskBandNewLabel,
        isPrimary: riskBandUser === undefined || riskBandUser === riskBandNew,
      };
    } else if (riskBandUser && bandIndex === portfolioBands[riskBandUser].riskBandIndex) {
      return {
        label: indicatorLabels?.user,
        isPrimary: true,
      };
    }
  }

  if (editMode) {
    if (
      (bandIndex === riskBandNew && !showPortfolioBands) ||
      (showPortfolioBands && portfolioBands && bandIndex === portfolioBands[riskBandNew].riskBandIndex)
    ) {
      return {
        isPrimary: true,
        label: riskBandNewLabel,
      };
    } else if (bandIndex === riskBandCurrent) {
      return {
        isPrimary,
        label: riskBandCurrent === sliderBand ? indicatorLabels?.user : indicatorLabels?.current,
      };
    } else if (bandIndex === sliderBand) {
      return {
        isPrimary,
        label: sliderBand === riskBandNew ? indicatorLabels?.new : indicatorLabels?.user,
      };
    }
  } else {
    if (bandIndex === riskBandNew) {
      return { label: riskBandNewLabel, isPrimary: riskBandUser === undefined || riskBandUser === riskBandNew };
    } else if (bandIndex === riskBandUser) {
      return {
        label: indicatorLabels?.user,
        isPrimary: true,
      };
    } else if (bandIndex === riskBandCurrent) {
      return {
        isPrimary,
        label: indicatorLabels?.current,
      };
    }
  }
  return {
    isPrimary,
    label: '',
  };
};
