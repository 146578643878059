import Decimal from 'decimal.js-light';
import { pipe } from 'fp-ts/lib/function';

import { Locale } from '~/utils/contentstack';

export interface FormatPercentageOptions {
  convertRatioToPercentage?: boolean;
  decimals?: number | null;
  locale?: Locale;
  removeTrailingZeroes?: boolean;
}

export const formatPercentage = (
  value: number | Decimal,
  {
    convertRatioToPercentage = true,
    decimals = 2,
    removeTrailingZeroes = true,
    locale = Locale.en_us,
  }: FormatPercentageOptions = {},
): string =>
  pipe(
    value,
    v => new Decimal(v),
    v => (convertRatioToPercentage ? v.times(100) : v),
    v => (decimals !== null ? v.toFixed(decimals) : v.toString()),
    v => (removeTrailingZeroes ? parseFloat(v).toString() : v),
    v => (locale === Locale.fr_ca ? `${v.replace('.', ',')} %` : `${v}%`),
  );

/**
 * Gets the number format properties based on the provided locale.
 *
 * This function returns the appropriate decimal and thousand separators
 * for the given locale. By default returns '.' and ',' as the decimal and
 * thousand separators respectively.
 * Only `Locale.fr_ca` is specially handled. Add cases below as necessary.
 *
 * @param {Locale} locale - The locale to determine the number format properties.
 * @returns {{ decimalSeparator: string; thousandSeparator: string }} An object containing the decimal and thousand separators.
 */
export const getNumberFormatPropsByLocale = (
  locale: Locale,
): { decimalSeparator: string; thousandSeparator: string } => {
  switch (locale) {
    case Locale.fr_ca:
      return { decimalSeparator: ',', thousandSeparator: ' ' };
    default:
      return { decimalSeparator: '.', thousandSeparator: ',' };
  }
};
